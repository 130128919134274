<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Detalle Documento
            {{ dteData.correlativo_interno }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          {{ dteData.tipo_pago }}
        </v-toolbar>
        <v-container>
          <v-skeleton-loader
            :loading="loading"
            type="list-item-avatar, divider, list-item-three-line, card-heading, image"
          >
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-card height="100%">
                  <v-card-title>
                    Datos DTE
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="dteData.estado == 'REGISTRADO'"
                      class="mx-2"
                      fab
                      dark
                      color="indigo"
                      @click="getOrderPDF"
                    >
                      <v-icon dark>mdi-printer</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="text--primary">
                    <p>
                      <strong>Tipo :</strong>
                      {{ dteData.tipo_dte }}
                    </p>
                    <!-- TODO:  Agregar validacion y boton de cancelado-->
                    <div
                      v-if="
                        dteData.estado == 'REGISTRADO' ||
                          dteData.estado == 'ANULADO'
                      "
                    >
                      <p>
                        <strong>Serie :</strong>
                        {{ dteData.serie }}
                      </p>
                      <p>
                        <strong>Número :</strong>
                        {{ dteData.numero }}
                      </p>
                      <p>
                        <strong>Autorización :</strong>
                        {{ dteData.autorizacion }}
                      </p>
                      <p>
                        <strong>Fecha Emisión :</strong>
                        {{ dteData.fecha_emision | date }}
                      </p>
                      <p>
                        <strong>Fecha Autorización :</strong>
                        {{ dteData.fecha_certificacion | date }}
                      </p>
                      <v-row>
                        <v-col cols="12" md="10">
                          <strong>{{ dteData.estado }}</strong>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-icon
                            medium
                            color="success darken-2"
                            v-if="dteData.estado == 'REGISTRADO'"
                          >
                            mdi-check-outline
                          </v-icon>
                          <v-icon medium color="red darken-2" v-else>
                            mdi-minus-circle-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="dteData.estado == 'EMITIDO'">
                      <p>
                        <strong>Fecha Emisión :</strong>
                        {{ dteData.fecha_emision | date }}
                      </p>
                      <v-row>
                        <v-col cols="12" md="10">
                          <strong>{{ dteData.estado }} </strong>(listo para
                          registrar)
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-icon medium color="red darken-2">
                            mdi-close-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                      <br />
                    </div>
                    <div v-if="dteData.estado == 'CANCELADO'">
                      <p>
                        <strong>Fecha Emisión :</strong>
                        {{ dteData.fecha_emision | date }}
                      </p>
                      <v-row>
                        <v-col cols="12" md="10">
                          <strong>{{ dteData.estado }} </strong>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-icon medium color="red darken-2">
                            mdi-delete-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-card height="100%">
                  <v-card-title> Datos Receptor </v-card-title>
                  <v-card-text class="text--primary">
                    <p>
                      <strong>Nombre :</strong>
                      {{ dteData.nombre_receptor }}
                    </p>
                    <p>
                      <strong>NIT :</strong>
                      {{ dteData.nit_receptor }}
                    </p>
                    <p>
                      <strong>Dirección :</strong>
                      {{ dteData.direccion_receptor }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div v-if="dteData.estado != 'ANULADO'">
              <v-card-title>Detalle de DTE</v-card-title>
              <v-data-table
                :headers="headers"
                :items="dteData.details"
                hide-default-footer
                disable-apagination
                disable-pagination
                disable-sort
                class="elevation-1"
              >
                <template v-slot:[`item.cantidad`]="{ item }">
                  {{ item.cantidad | currency }}
                </template>
                <template v-slot:[`item.precio`]="{ item }">
                  {{ item.precio | currency }}
                </template>
                <template v-slot:[`item.precio_unidad`]="{ item }">{{
                  item.precio_unidad | currency
                }}</template>
              </v-data-table>
              <br />
              <div v-if="dteData.estado == 'CANCELADO'">
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow">
                      Este DTE fue descartado antes de ser registrado.</v-col
                    >
                  </v-row>
                </v-alert>
              </div>
              <v-card v-else>
                <v-card-title>Totales</v-card-title>
                <v-simple-table class="text--primary">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Descripción</th>
                        <th class="text-right">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Total Impuesto</td>
                        <td class="text-right">
                          {{ dteData.total_impuesto | currency }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Grabable</td>
                        <td class="text-right">
                          {{ dteData.total_grabable | currency }}
                        </td>
                      </tr>
                      <tr>
                        <td>Gran Total</td>
                        <td class="text-right">
                          {{ dteData.gran_total | currency }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>
            <div v-else>
              <v-card>
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow"> Este DTE fue anulado. </v-col>
                  </v-row>
                </v-alert>
              </v-card>
            </div>
            <v-snackbar v-model="snackbarStatus">
              {{ snackbarText }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbarStatus = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </v-skeleton-loader>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import requests from "@/services/requests";
import blobResponse from "@/utils/response.blob";

export default {
  props: {
    dialog: { type: Boolean, required: true },
    dteId: { type: String, required: true }
  },
  data() {
    return {
      loading: false,
      snackbarStatus: false,
      snackbarText: "",
      dteData: {},
      registering: false,
      headers: [
        { text: "Descripción", value: "descripcion" },
        { text: "Tipo", value: "tipo", align: "center" },
        { text: "Cantidad", value: "cantidad", align: "end" },
        { text: "Precio", value: "precio_unidad", align: "end" },
        { text: "Subtotal", value: "precio", align: "end" }
      ]
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    getDetail() {
      this.loading = true;
      requests
        .get("/api/dtes/" + this.dteId)
        .then(response => {
          if (response.status == 200) {
            this.dteData = response.data;
          } else {
            console.log(response);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getOrderPDF() {
      requests.get("/api/dtes/pdf/" + this.dteData.id).then(response => {
        if (response.status == 200) {
          blobResponse(response.data, "application/pdf");
        } else {
          this.snackbarText = "Ha ocurrido un error";
          this.snackbarStatus = true;
        }
      });
    }
  },
  mounted() {
    this.getDetail();
  }
};
</script>
